<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-input v-model="dataForm.serviceName" placeholder="产品名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <service-item-cascader v-model="dataForm.itemCode"></service-item-cascader>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList(true)">查询</el-button>
        <el-button type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="logoUrl"
        header-align="center"
        align="center"
        label="产品图片">
        <template slot-scope="scope">
          <el-image style="width: 100px;height: 100px;" :src="$cons.SERVER_PATH + scope.row.logoUrl">
            <div slot="error">
              <div :title="scope.row.serviceName" class="item-error">
                {{scope.row.serviceName && scope.row.serviceName.length > 12 ? scope.row.serviceName.substr(0,11) + '...' : scope.row.serviceName}}
              </div>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="serviceName"
        header-align="center"
        align="center"
        label="产品名称">
      </el-table-column>
      <el-table-column
        prop="itemName"
        header-align="center"
        align="center"
        label="服务类别">
      </el-table-column>
      <el-table-column
        prop="itemDesc"
        header-align="center"
        align="center"
        label="服务简介">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="toProduct(scope.row)">详情</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="tac mt-3"
      background
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>

<script>
  import ServiceItemCascader from '../../../components/service-item-cascader'
  export default {
    components: { ServiceItemCascader },
    data () {
      return {
        dataForm: {
          serviceName: '',
          itemCode: ''
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: []
      }
    },
    created () {
      this.getDataList(true)
    },
    methods: {
      // 获取数据列表
      getDataList (refresh) {
        this.dataListLoading = true
        if (refresh) {
          this.pageIndex = 1
        }
        this.$http({
          url: this.$http.adornUrl(this.$api.VIEW.LIST),
          method: 'get',
          params: this.$http.adornParams({
            'page': this.pageIndex,
            'limit': this.pageSize,
            'serviceName': this.dataForm.serviceName,
            'itemCode': this.dataForm.itemCode
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList(true)
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList(false)
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      toProduct (item) {
        if (item.serviceStatus !== 1) {
          this.$message.info('该产品未上架')
          return
        }
        this.$router.push({name: 'service', query: {id: item.serviceId}})
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定对进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl(this.$api.VIEW.DELETE),
            method: 'post',
            data: this.$http.adornData(ids, false)
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }).catch(() => {})
      }
    }
  }
</script>
<style scoped>
.item-error {
  width: 100px;
  height: 100px;
  background-image: url("../../../../assets/img/product/default_3.png");
  background-size: cover;
  font-size: 1rem;
  /*font-weight: 00;*/
  line-height: 1.5rem;
  padding: 20px 10px;
  color: #FFFFFF;
  cursor: default;
}
</style>
